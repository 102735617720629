<template>
    <div class="table-data">
        <el-table
            ref="multipleTable"
            :row-key="config.rowkey"
            :data="tableData"
            :default-expand-all="table_config.isExpandAll || false"
            :height="table_config.height || null"
            :border="table_config.border || false"
            :indent="table_config.indent"
            :stripe="stripe"
            @filter-change="handleFilterChange"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
            @cell-click="handleCellClick"
            @sort-change="handleSortChange"
            @header-click="headerClick"
            @select="onTableSelect"
            :header-cell-style="{background:backgroundHeader,color:headerColor}"
            :cell-style="{color:headerColor}"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" label-width="auto"
            :row-class-name="(row) => tableRowClassName(row.row,config.isCloseRow)">
            <el-table-column
                v-if="table_config.leftEmpty"
                width="50"
            >
            </el-table-column>
            <el-table-column
                v-if="table_config.check"
                type="selection"
                :selectable="table_config.checkSelectAble"
                width="50"
                align="center"
                fixed="left"
            >
            </el-table-column>
            <el-table-column
                v-if="table_config.showIndex"
                class-name="index-column"
                type="index"
                label="序号"
                align="center"
                :fixed="table_config.indexFixed"
                :width ="table_config.showIndexWidth || 50">
                <!-- :width="table_config.showIndexWidth || 50" -->
            </el-table-column>
            <template v-for="item in this.table_config.thead">
                <el-table-column
                    v-if="item.type === 'switch' && (item.special === '' || typeof item.special === 'undefined')"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :key="item.id"
                    align="center">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row[item.prop]"
                            active-value="1"
                            inactive-value="0"
                            :disabled="item.disabled"
                            @change="switchHandler(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'link'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :key="item.id"
                    :fixed="item.fixed"
                    align="center">
                    {{ item.fixed }}
                    <template slot-scope="scope">
                        <template v-if="item.prop !== 'schoolName'">
                            <div v-for="(subItem, subIndex) in scope.row[item.prop]" :key="subIndex">
                                <el-button
                                :type="item.btnType"
                                @click="linkClick(scope.row, subItem, subIndex)">{{ subItem }}</el-button>
                            </div>

                        </template>
                        <template v-else>
                            <el-button
                                :type="item.btnType"
                                @click="linkClick(scope.row)">{{ scope.row[item.prop] }}</el-button>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'detail'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :key="item.id"
                    :fixed="item.fixed"
                    align="center">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row[item.prop] !== 'none'"
                            :type="item.btnType"
                            @click="detailClick(item, scope.row)"
                        >{{ scope.row[item.prop] }}</el-button>
                        <template v-if="item.other && item.other.length > 0 && scope.row.other">
                            <template v-for="(subItem, subIndex) of item.other">
                                <span v-has-permi="subItem.auth" class="fn-line" :key="subIndex">|</span>
                                <el-button
                                    :key="subIndex + 1"
                                    :type="subItem.btnType"
                                    v-has-permi="subItem.auth"
                                    @click="detailClick(item, scope.row, subItem)"
                                >
                                    {{ subItem.text }}
                                </el-button>
                            </template>
                        </template>
                        <span v-if="!item.other && scope.row[item.prop] == 'none'">-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'modify'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :key="item.id"
                    :fixed="item.fixed"
                    align="center">
                    <template slot-scope="scope">
                        <slot name="modify" :modifyData="scope.row"></slot>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'smj'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :key="item.id"
                    align="center">
                    <template slot-scope="scope">
                        <slot :name="item.slotName" :data="scope.row"></slot>
                    </template>
                </el-table-column>

                <el-table-column
                    v-else-if="item.type === 'progress'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    align="center">
                    <template slot-scope="scope">
                        <span v-html="item.callBack && item.callBack(scope.row, item.prop)"></span>
                        <el-progress type="circle" :percentage="scope.row.openingRate" :color="scope.row.status === '0' ? '#BFBFBF' : item.color" :width="item.width" :stroke-width="item.strokeWidth" ></el-progress>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'popover'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    align="center">
                    <template slot-scope="scope">
                        <el-popover v-if="scope.row[item.prop] && scope.row[item.prop].split(',').length > 0" placement="top">
                          <div class="popover-wrapper">
                            <el-tag
                              type="success"
                              v-for="(item, index) in scope.row[item.prop].split(',').filter(i => i !== '')"
                              :key="index"
                            >{{ item }}</el-tag>
                          </div>
                          <el-tag v-if="item.notShowMore" type="success" slot="reference">{{ scope.row[item.prop].split(',').length > 1 ?  scope.row[item.prop].split(',')[0]+'...' : scope.row[item.prop].split(',')[0]}}</el-tag>
                          <el-tag v-else type="success" slot="reference">{{ scope.row[item.prop].split(',')[0] }}...</el-tag>
                        </el-popover>
                    </template>
                </el-table-column>
                <!-- 文字过长时，使用... ，鼠标移上去显示文字 -->
                <el-table-column
                    v-else-if="item.type === 'popoverText'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :show-overflow-tooltip="true"
                    align="center">
                    <!-- <template slot-scope="scope">
                        <div v-if="scope.row[item.prop] && scope.row[item.prop].length <= (item.maxLength || 5)">{{ scope.row[item.prop] }}</div>
                        <el-popover v-else placement="top" trigger="hover" :open-delay="200">
                          <div style="max-width: 300px;line-height: 25px">{{ scope.row[item.prop] }}</div>
                          <div slot="reference" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
                            {{ scope.row[item.prop]}}
                          </div>
                        </el-popover>
                    </template> -->
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'images'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :fixed="item.fixed"
                    align="center">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row[item.prop] !== undefined"
                            :src="scope.row[item.prop]"
                            :style="{
                                'width': item.width || '40px',
                                'height': item.height || '40px',
                                'border-radius': item.borderRadius || '50%'
                            }"
                            :preview-src-list="item.showPreview ? [scope.row[item.prop]] : []">
                          <div slot="error" class="image-slot" style="height: 100%;background-color: #e7ebf5;">
                              <img v-if="item.imgErrorAdr" :src="item.imgErrorAdr" alt="" style="width: 100%;height: 100%">
                          </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'dropdown'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    align="center">
                    <template slot-scope="scope">
                      <el-dropdown :disabled="item.disabled" trigger="click">
                      <span class="el-dropdown-link">
                        <span v-if="item.list.find(i => i.value == scope.row[item.prop])" class="dropdown-title">
                            {{ item.list.find(i => i.value == scope.row[item.prop]).label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item v-for="(item, index) in item.list" :key="index" @click.native="dropDownClick(scope.row, item)">{{ item.label }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                </el-table-column>
                <!-- Invalid ↓ -->
                <el-table-column
                    v-else-if="item.type === 'Invalid'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :sortable="item.sortable ? item.sortable : false"
                    :fixed="item.fixed"
                    :width="item.labelWidth"
                    :min-width="item.minWidth ? item.minWidth : (item.labelWidth ? '' : '100px')"
                    :show-overflow-tooltip="true"
                    :align="item.align || 'center'">
                    <template slot-scope="scope">
                        <span :class="{red:item.callBack(scope.row, item.prop)=='已失效'||item.callBack(scope.row, item.prop)=='失效'}" v-html="item.callBack && item.callBack(scope.row, item.prop)"></span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'function'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :sortable="item.sortable ? item.sortable : false"
                    :fixed="item.fixed"
                    :width="item.labelWidth"
                    :show-overflow-tooltip = 'true'
                    :align="item.align || 'center'">
                    <template slot-scope="scope">
                        <span v-html="item.callBack && item.callBack(scope.row, item.prop)"></span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'multilayer'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :show-overflow-tooltip="true"
                    :key="item.id"
                    align="center">
                    <template slot-scope="scope">
                        <div
                        class="list"
                            v-for="(subItem, index) in scope.row[item.prop]"
                            :key="index"
                        >{{ subItem }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type == 'switch' && item.special == 'multilayer'"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.labelWidth"
                    :key="item.id"
                    align="center">
                    <template slot-scope="scope">
                        <div
                            v-for="(subItem, subIndex) of scope.row[item.prop]"
                            :key="subIndex"
                            >

                            <el-switch
                            :key="subIndex"
                            v-model="scope.row[item.prop][subIndex]"
                            active-value="1"
                            inactive-value="0"
                            @change="switchHandlerMultilayer(scope.row, subIndex)"
                            >
                            </el-switch>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'slot'"
                    :class-name="item.className || ''"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :fixed="item.fixed"
                    :min-width="item.minWidth"
                    :width="item.labelWidth"
                    :show-overflow-tooltip = 'item.tooltip ? false : true'
                    :align="item.align || 'center'">
                    <template slot-scope="scope">
                        <slot :name="item.slotName" :data="scope.row" :item="item"></slot>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'slotAndIcon'"
                    :class-name="item.className || ''"
                    :render-header="item.renderHeader"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :min-width="item.minWidth"
                    :width="item.labelWidth"
                    :show-overflow-tooltip = 'item.tooltip ? false : true'
                    :fixed="typeof item.fixed !== 'undefined' ? item.fixed : 'right'"
                    :align="item.align || 'center'">
                    <template slot-scope="scope">
                        <slot :name="item.slotName" :data="scope.row" :item="item"></slot>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'slot1'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    fixed="left"
                    align="center">
                    <template slot-scope="scope">
                        <slot :name="item.slotName" :data="scope.row"></slot>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'desensitize'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :show-overflow-tooltip = 'true'
                    align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row[item.prop]">
                            <span :style="scope.row[item.prop].indexOf('*') === -1 ? {color: '#3672d9'} : {}">{{scope.row[item.prop]}}</span>
                            <i v-has-permi="item.auth" @click="item.callBack(scope.row)" class="el-icon-view" :style="scope.row[item.prop].indexOf('*') === -1 ? {color: '#3672d9'} : {}"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'tooltipThead'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'center'"
                    :fixed="item.fixed"
                    :show-overflow-tooltip="true"
                    :class-name="item.className"
                    :minWidth="item.minWidth"
                >
                    <template slot="header">
                        <text-tooltip :content="item.label"></text-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'setHeader'"
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :width="item.labelWidth"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'center'"
                    :fixed="typeof item.fixed !== 'undefined' ? item.fixed : 'right'"
                    :show-overflow-tooltip="true"
                    :class-name="item.className"
                    :minWidth="item.minWidth"
                >
                    <template slot="header" slot-scope="scope">
                        <slot :name="item.slotName" :data="scope.row"></slot>
                    </template>
                    <template  slot-scope="scope">
                        <slot :name="item.slotName1" :data="scope.row"></slot>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.type === 'customization'"
                    :key="item.id"
                    label-class-name="table_header">
                    <template
                        slot="header"
                        slot-scope="scope"
                    >
                        <i
                            style="cursor: pointer;"
                            :class="item.class"
                            @click="customization"
                        ></i>
                    </template>
                </el-table-column>
                <el-table-column
                    v-else
                    :prop="item.prop"
                    :label="item.label"
                    :key="item.id"
                    :min-width="item.minWidth"
                    :width="item.labelWidth"
                    :sortable="item.sortable ? item.sortable : false"
                    :align="item.align || 'center'"
                    :fixed="item.fixed"
                    :show-overflow-tooltip="true"
                    :filters="item.filters"
                    :column-key="item.columnKey"
                    :filtered-value="item.filterValue"
                    :formatter="item.formatter"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row[item.prop] || scope.row[item.prop] === 0" class="text-ellipsis">{{ scope.row[item.prop] }}</div>
                        <div v-else-if="item.defaultValue" class="text-ellipsis">{{ item.defaultValue }}</div>
                        <div v-else-if="item.label">-</div>
                    </template>
                </el-table-column>

            </template>
        </el-table>
    </div>
</template>

<script>
import { createLogger } from 'vuex';
import TextTooltip from "./TextTooltip";
import TableHeader from "./TableHeader"
export default {
    name: "TableData",
    components: {TextTooltip,TableHeader},
    props: {
        config: {
            type: Object,
            default: () => {
            }
        },
        tableData: {
            type: Array,
            default: () => []
        },
        backgroundHeader: {
            type: String,
            default: '#fafbfc'
        },
        headerColor: {
            type: String,
            default: '#595959'
        },
        rows: {
            type: Array
        },
        toTop: {
            type: Boolean,
            default: true
        },
        stripe: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            vm: null,
            /*
            *	thead 表头
            *	check 复选框
            *
            */
            table_config: {
                thead: [],
                leftEmpty: false,
                check: true,
                checkSelectAble: function() {
                    return true
                },
                showIndex: false,
                indexFixed: false,
                rowkey: '',
                isExpandAll: '',
                border: false,
                height: '',
                showIndexWidth: '',
                isCloseRow: {
                  key: '',
                  value: ''
                },
                indent: 16
            },
            haschild: true,
            // bjColorc: ['#ff5857', '#3385ff'],
        }
    },
    mounted() {
        this.vm = this;
    },
    methods: {
        init() {
            for (let key in this.config) {
                if (Object.keys(this.table_config).includes(key)) {
                    this.table_config[key] = this.config[key];
                }
            }
            console.log(this.table_config,'this.table_config')
        },
        handleRowClick(ev) {
            this.$emit('handleRowClick', ev)
        },
        handleCellClick(ev, column, cell){
            this.$emit('handleCellClick', ev, column, cell)
        },
        headerClick (ev) {
            this.$emit('headerClick', ev)
        },
        handleSortChange(ev){
            this.$emit('handleSortChange', ev)
        },
        handleFilterChange(ev){
            this.$emit('handleFilterChange',ev)
        },
        handleSelectionChange(ev) {
            this.$emit('handleSelectionChange', ev)
        },
        switchHandler(ev) {
            this.$emit('switchHandler', ev)
        },
        switchHandlerMultilayer (ev, idx) {
            console.log('ch')
            this.$emit('switchHandlerMultilayer', ev, idx)
        },
        linkClick(ev, item, index) {
            this.$emit('linkClick', ev, item, index)
        },
        detailClick (prop, row, other) {
        console.log(other,'other')
            if (other) {
                this.modifyRowInfo(prop, row, other)
            } else {
                this.$emit('detailClick', prop, row)
            }

        },
        dropDownClick(ev, data) {
            this.$emit('dropDownClick', ev, data)
        },
        tableRowClassName (row, isCloseRow) {
          if (typeof isCloseRow !== 'undefined' && row[isCloseRow.key] === isCloseRow.value) {
            return 'close-row'
          } else if(row && row.childFlag){//这里是为了判断是否有子集【树型数据】,给子节点添加背景色
            return 'childSty-row'
          }
          else {
            return ''
          }
        },
        toggleRowSelection () {
            if (this.rows) {
                this.rows.forEach((row) => {
                    this.$nextTick(() => {
                        this.$refs.multipleTable.toggleRowSelection(this.tableData[`${row}`])
                    })
                })
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        customization () {
            this.$emit('customization')
        },
        onTableSelect (rows, row) {
            this.$emit('onTableSelect', rows)
        },
        /**
         * @Description: 公用方法
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2024-06-21 09:54:42
         */
        modifyRowInfo (...args) {
            this.$emit('modifyRowInfo', args)
        }
    },
    watch: {
        config: {
            handler(newValue) {
                this.init();

            },
            immediate: true,
            deep: true
        },
        tableData: {
            handler () {
                if (this.toTop) {
                    this.$nextTick(() => {
                        this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                    })
                }
            },
            deep: true
        }
    }
}
</script>
<style >
.el-tooltip__popper.is-dark {
    /* background: rgba(247, 247, 248, 0.9)!important;
    color: #000; */
    max-width: 300px;

    line-height: 24px;
  }
</style>
<style lang="scss" scoped>
.el-icon-view {
    margin-left: 5px;
}
// .list:nth-of-type(2n-1) {
// 		background-color: red;
// 	}
// .list:nth-of-type(2n) {
// 		background-color: blueviolet;
// 	}
.table-data {
    box-sizing: border-box;
    background-color: #fff;
    padding-bottom: 10px;
    ::v-deep .el-table {
        .left-empty .cell {
            display: none;
        }

        th.el-table__cell.is-right.is-sortable > .cell {
            padding-right: 24px;
        }

        .caret-wrapper {
            position: absolute;
            top: -3px;
        }

        .el-table__row {
            height: 56px;
        }

        td.el-table__cell, th.el-table__cell.is-leaf {
            border: unset;
        }

        .el-table__fixed-right::before,
        .el-table__fixed::before {
            background-color: transparent;
        }

        .close-row .cell {
            color: #BFBFBF !important;
        }
        .close-row .cell .el-dropdown-link {
            color: #BFBFBF !important;
        }
        .childSty-row {
            background-color: #F7F9FA !important;
        }

        /* 鼠标悬浮行 */
        .el-table__body .el-table__row.hover-row td {
            background-color: #EBEFF5 !important;
        }

        .el-button--text {
            padding: 0;
        }
    }
}
.el-table {
  /deep/.close-row .cell {
    color: #BFBFBF !important;
  }
  /deep/.close-row .cell .el-dropdown-link {
    color: #BFBFBF !important;
  }
  /deep/.childSty-row {
  background-color: #F7F9FA!important;
}
    /deep/ thead {
        color: #595959;
    }
    .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}
/deep/ .el-table tbody tr:hover>td {
  background-color:#fafbfc!important
}
.el-dropdown-link {
  cursor: pointer;
  font-size: 12px;
}
.popover-wrapper {
  max-width: 310px;
  .el-tag {
    margin: 5px;
    text-align: center;
  }
}
    .red{
        color: rgb(240, 12, 12);
    }
    
    .fn-line {
        padding: 0 6px;
        color: #3DA7F2;
    }
</style>
